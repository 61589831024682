/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef, useCallback, useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { useSprings, a } from 'react-spring';

const styles = {
  container: {
    position: 'relative', height: '100%', width: '100%', overflow: 'hidden', backgroundColor: 'white',
  },
  item: { position: 'absolute', height: '100%', willChange: 'transform' },
};

/**
 * Calculates a spring-physics driven infinite slider
 *
 * @param {Array} items - display items
 * @param {Function} children - render child
 * @param {number} width - fixed item with
 * @param {number} visible - number of items that muste be visible on screen
 */
export default function Slider({
  items, width = 600, visible = 4, style, children, onReachStart = () => {}, onReachEnd = () => {},
}) {
  const [lastY, setY] = useState(0);
  const idx = useCallback((x, l = items.length) => (x < 0 ? x + l : x) % l, [items]);
  const getPos = useCallback((i, firstVis, firstVisIdx) => idx(i - firstVis + firstVisIdx), [idx]);
  const [springs, set] = useSprings(items.length, (i) => ({ x: (i < items.length - 1 ? i : -1) * width }));
  const prev = useRef([0, 1]);

  const runSprings = useCallback(
    (y, vy) => {
      const firstVis = idx(Math.floor(y / width) % items.length);
      const firstVisIdx = vy < 0 ? items.length - visible - 1 : 1;

      // call event
      if (firstVis === items.length - 1 && lastY < y) {
        // console.log('end');
        onReachEnd();
      }
      if (firstVis === 0 && lastY > y) {
        // console.log('start');
        onReachStart();
      }
      setY(y);
      set((i) => {
        const position = getPos(i, firstVis, firstVisIdx);
        const prevPosition = getPos(i, prev.current[0], prev.current[1]);
        const rank = firstVis - (y < 0 ? items.length : 0) + position - firstVisIdx;
        const configPos = vy > 0 ? position : items.length - position;
        return {
          x: (-y % (width * items.length)) + width * rank,
          immediate: vy < 0 ? prevPosition > position : prevPosition < position,
          config: { tension: (1 + items.length - configPos) * 200, friction: 30 + configPos * 40 },
        };
      });
      prev.current = [firstVis, firstVisIdx];
    },
    [idx, getPos, width, visible, set, items.length, setY, lastY],
  );

  const wheelOffset = useRef(0);
  const dragOffset = useRef(0);
  const bind = useGesture({
    onDrag: ({ offset: [x], vxvy: [vx] }) => vx && ((dragOffset.current = -x), runSprings(wheelOffset.current + -x, -vx)),
    onWheel: ({ offset: [, y], vxvy: [, vy] }) => vy && ((wheelOffset.current = y), runSprings(dragOffset.current + y, vy)),
  });

  return (
    <div {...bind()} style={{ ...style, ...styles.container }}>
      {springs.map(({ x, vel }, i) => (
        <a.div key={i} style={{ ...styles.item, width, x }} children={children(items[i], i)} />
      ))}
    </div>
  );
}
