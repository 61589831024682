/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { Link } from 'gatsby';
import { animated, useSpring } from 'react-spring';
import ReactPlayer from 'react-player';
import Sticky from 'react-sticky-el';

import InfiniteSlider from '../components/slider';
// import SliderDrag from '../components/sliderDrag';
import Layout from '../components/layout';
import SEO from '../components/seo';
import APPS from '../constants/constant';

import './home.scss';

const windowGlobal = typeof window !== 'undefined' && window;

const nextIcon = require('../images/icon-arrow-next-white@3x.png');
const nextIconPurple = require('../images/icon-arrow-next@3x.png');
const trucksterThumbnail = require('../images/Truckster-thumbnail.png');
const apolloThumbnail = require('../images/Apollo-thumbnail.png');
const farmlandThumbnail = require('../images/Farmland-thumbnail.png');
const joymapThumbnail = require('../images/Joymap-thumbnail.png');
// const JRThumbnail = require('../images/JR-thumbnail.png');
const MNEThumbnail = require('../images/MNE-thumbnail.png');
const comigoThumbnail = require('../images/Comigo-thumbnail.png');
const raiThumbnail = require('../images/RestorationAI-thumbnail.png');

const ApolloVideo = require('../videos/Apollo-thumbnail.mp4');
const TrucksterVideo = require('../videos/truckster-thumbnail.mp4');
const MNEVideo = require('../videos/Freedominvest-thumbnail.mp4');
const ComigoVideo = require('../videos/Comigo-thumbnail.mp4');
const RAIVideo = require('../videos/RA-thumbnail.mp4');

const nextBlackIcon = require('../images/icon-arrow-next-gray@3x.png');
const nextWhiteIcon = require('../images/icon-arrow-next-white@3x.png');

const pages = [
  {
    src: TrucksterVideo, link: APPS.TRUCKSTER, color: 'white', title: 'Truckster', description: 'Discover food trucks nearby.',
  },
  {
    src: ApolloVideo, link: APPS.APOLLO, color: '#191d3d', title: 'Apollo', description: 'Making the physician hiring process easier, simpler, and faster than ever before.',
  },
  {
    src: MNEVideo, link: APPS.MNE, color: '#191d3d', title: 'Freedom Invest', description: 'A safer way to invest.',
  },
  {
    src: RAIVideo, link: APPS.RAI, color: 'white', title: 'RestorationAI', description: 'Properly Estimate and Supplement Insurance Claims.',
  },
  {
    src: ComigoVideo, link: APPS.COMIGO, color: '#191d3d', title: 'Comigo', description: 'A platform to find new collaborators on campus for one-of-a-kind ideas.',
  },
];

const ProjectImage = ({
  img, alt, index = 0, link,
}) => {
  const fade = useSpring({
    opacity: 1, delay: index * 400, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });

  return (
    <animated.div
      style={fade}
      className="Project-Image"
      onClick={() => window.open(link, '_blank')}
    >
      <img src={img} alt={alt} />
    </animated.div>
  );
};

const PanelItem = ({ scale, item }) => {
  const [isHover, setHover] = useState(false);

  return (
    <animated.div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ scale }}
    >
      <div>
        <ReactPlayer
          className="React-Player__Container"
          url={item.src}
          style={{ paddingLeft: 20, paddingRight: 20 }}
          width="100%"
          height="100%"
          playing={isHover}
          loop
        />
        <div className="Video-Container">
          <div className="Video-Container__Title" style={{ color: item.color }}>{item.title}</div>
          <div className="Video-Container__Des" style={{ color: item.color }}>{item.description}</div>
          <div className="Video-Container__Link" style={{ color: item.color }} onClick={() => window.open(item.link, '_blank')}>
            Open site
            <img
              src={item.color === 'white' ? nextWhiteIcon : nextBlackIcon}
              alt=""
              style={{
                width: 30, height: 16, margin: 0, marginLeft: 10,
              }}
            />
          </div>
        </div>
      </div>
    </animated.div>
  );
};

const IndexPage = () => {
  const fade = (delay = 0) => useSpring({
    opacity: 1,
    delay,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(60px)' },
    config: { duration: 300 },
  });
  const [isHover, setHover] = useState(false);
  const hoverStyle = useSpring({ padding: isHover ? '2%' : '0' });
  const [isSticky, setSticky] = useState(true);
  const [slideCompleted, setComplete] = useState(false);

  const height = windowGlobal.innerHeight * 0.8;
  const width = height * 1.78;

  // useEffect(() => {
  //   // TODO: Remove Home page for now
  //   navigate('/what-we-do');
  // }, []);

  const disableScroll = (disable = true) => {
    const layout = document.getElementById('layoutContainer');
    if (layout && !slideCompleted) {
      if (disable) {
        setSticky(true);
        layout.style.overflow = 'hidden';
        layout.style.height = '100vh';
        layout.scrollTop = 500;
      } else {
        layout.style.overflow = 'visible';
        window.scrollTo(0, 500);

        setSticky(false);
      }
    }
  };

  const handleComplete = () => {
    setComplete(true);
    disableScroll(false);
  };

  return (
    <Layout>
      <SEO title="Home" />
      <div className="Home">
        <div className="Home__Top">
          <animated.div style={fade(0)} className="Home__Title">
            We build mobile & web apps for startups.
          </animated.div>
          <animated.div style={{ ...fade(400), display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/contact-us/" className="Home__Next">
              Let&apos;s create
              something together
              <div className="Home__Next-icon">
                <img src={nextIcon} alt="" />
              </div>
              <img src={nextIconPurple} alt="" className="Home__Next-icon__mobile" />
            </Link>
          </animated.div>
        </div>
        <Sticky disabled={!isSticky}>
          <animated.div
            style={fade(800)}
            className="Home__Projects"
            onMouseEnter={() => disableScroll(true)}
            onMouseLeave={() => { setComplete(false); disableScroll(false); }}
          >
            {windowGlobal && (
            <InfiniteSlider
              items={pages}
              width={width}
              visible={3}
              onReachEnd={() => handleComplete()}
              onReachStart={() => handleComplete()}
            >
              {(item, index) => (
                <PanelItem item={item} key={index} scale={1} />
              )}
            </InfiniteSlider>
            )}
          </animated.div>
        </Sticky>
        {/* <animated.div style={fade(800)} className="Home__Imgs__Desktop">
          <SliderDrag items={pages} className="Home__Img">
            {(item, index, scale) => (
              <PanelItem item={item} key={index} scale={scale} />
            )}
          </SliderDrag>
            </animated.div> */}
        <div className="Home__Imgs__Mobile">
          <ProjectImage index={2} img={trucksterThumbnail} alt="truckster" link={APPS.TRUCKSTER} />
          <ProjectImage index={3} img={apolloThumbnail} alt="apollo" link={APPS.APOLLO} />
          <ProjectImage index={4} img={comigoThumbnail} alt="comigo" link={APPS.COMIGO} />
          <ProjectImage index={5} img={farmlandThumbnail} alt="farmland" link={APPS.FARMLAND} />
          <ProjectImage index={6} img={joymapThumbnail} alt="joymap" link={APPS.JOYMAP} />
          <ProjectImage index={7} img={MNEThumbnail} alt="MNE" link={APPS.MNE} />
          <ProjectImage index={8} img={raiThumbnail} alt="RAI" link={APPS.RAI} />
          <animated.div
            className="Home__Imgs__Mobile_next"
            style={{ ...hoverStyle, backgroundColor: 'white' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Link to="/our-work/" className="Home__Imgs__Mobile_next_link">
              See more work
              <img src={nextIcon} alt="" className="icon-desktop" />
              <img src={nextIconPurple} alt="" className="icon-mobile" />
            </Link>
          </animated.div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
