const APPS = {
  TRUCKSTER: 'https://gotruckster.com/',
  APOLLO: 'https://apollohct.com/',
  COMIGO: 'https://comigo.co/',
  FARMLAND: 'https://www.farmlandfinder.com/',
  JOYMAP: 'https://www.joymap.co/',
  MNE: 'https://www.freedominvest.com/',
  CHARACTERID: 'https://app.mycharacterid.com/',
  RAI: 'https://www.restorationai.com/',
};

export default APPS;
